<template>
  <div>
    <CampaignsTable
      :items="campaigns"
      @create_campaign_clicked="create_campaign_clicked"
      @select_campaign_clicked="select_campaign_clicked"
      @delete_campaign_clicked="delete_campaign_clicked"
    />
    <div class="scroll-to-container"></div>
    <CampaignEdit
      v-if="campaign"
      :campaign = "campaign"
      @update:campaign="update_campaign"
      @create:campaign="create_campaign"
      @close_clicked="close_campaign_editor_clicked"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CampaignEdit from '@/view/pages/ml/shopitem/CampaignEdit.vue';
import CampaignsTable from '@/view/pages/ml/shopitem/CampaignsTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'campaigns',
  mixins: [ toasts ],
  components: {
    CampaignEdit,
    CampaignsTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {
    const loader = this.$loading.show();
    this.load_campaigns();
    loader & loader.hide();
  },

  data() {
    return {
      campaigns: [],
      campaign: null
    };
  },

  watch: {
    currentCompanyId(new_value) {
      if (new_value) {
        this.load_campaigns();
      }
    },
  },

  methods: {

    create_campaign_clicked() {
      this.campaign = { active: false, use_code: true };
      this.scroll_to_editor();
    },

    async select_campaign_clicked(id) {
      try {
        const res = await axios.get(`/campaign/${id}`);
        if (res.status === 200) {
          res.data.percent = Math.round(res.data.percent * 100); // convert from 0-1 to 0-100
          this.campaign = res.data;
          this.scroll_to_editor();
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_GET'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_GET'));
      }
    },

    async delete_campaign_clicked(id) {
      try {
        const res = await axios.delete(`/campaign/${id}`);
        if (res.status === 204) {
          this.campaigns = this.campaigns.filter(item => item.id !== id);
          if (this.campaign && this.campaign.id === id) { this.campaign = null; }
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.CAMPAIGNS.DELETED'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_DELETE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_DELETE'));
      }
    },

    async update_campaign(campaign) {
      // fixup
      campaign.code += '';
      campaign.max_uses = parseInt((campaign.max_uses ? campaign.max_uses : 0) + '');
      campaign.percent = campaign.percent / 100;

      try {
        const res = await axios.put(`/campaign/${campaign.id}`, campaign);
        if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.CAMPAIGNS.UPDATED'));
            const index = this.campaigns.findIndex(item => campaign.id === item.id);
            this.campaigns[index] = campaign;
            // Vue3 compatability for Vue.set
            this.campaigns = [...this.campaigns];
            this.campaign = null;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_UPDATE'));
        }
      }
      catch(err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_UPDATE'));
      }
    },

    async create_campaign(campaign) {
      // fixup
      campaign.code += '';
      campaign.max_uses = parseInt((campaign.max_uses ? campaign.max_uses : 0) + '');
      campaign.percent = campaign.percent / 100;

      try {
        const res = await axios.post(`/campaign`, campaign);
        if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.CAMPAIGNS.CREATED'));
            this.campaigns.push(res.data);
            this.campaign = null;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_CREATE'));
          }
      }
      catch(err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_CREATE'));
      }
    },

    close_campaign_editor_clicked() {
      this.campaign = null;
    },

    async load_campaigns() {
      this.campaigns = [];
      try {
        const res = await axios.get(`/campaign`);
        if (res.status === 200) {
            this.campaigns = res.data;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_LIST'));
        }
      }
      catch(err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.CAMPAIGNS.UNABLE_LIST'));
      }
    },

    scroll_to_editor() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];
        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    }
  }
};
</script>
